/*----------  Font Variables  ----------*/

$font-primary				: 'Roboto Condensed', sans-serif;
$font-secondary				: 'Montserrat', serif;
// $sub-font-01				: sans-serif;
// $sub-font-02				: sans-serif;

/*----------  Icon Fonts  ----------*/
$font-fontawesome			: 'Font Awesome 5 Free';

/*----------  Color Variables  ----------*/

/*----------  Bootstrap's Defaults  ----------*/

$brand-primary				: #809ad0;
$brand-secondary			: #282727;
$brand-success				: #28a745;
$brand-info					: #5d8ae9;
$brand-warning				: #ffc107;
$brand-danger				: #dc3545;
$brand-light				: #f0f5fe;
$brand-dark					: #4c4c4c;

/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;

/*-----  Unsorted Variables  -----*/

$color-01					: #b7caea;
$color-02					: #f9fbfe;

/*-----  Brands Variables  -----*/

/* Brand Primary */
// $brand-primary-01			: #;
// $brand-primary-02			: #;

/* Brand Info */
$brand-info-01				: #223f6e;
$brand-info-02				: #b7caea;

/* Brand Warning */
// $brand-warning-01			: #;
// $brand-warning-02			: #;

/* Brand Success */
// $brand-success-01			: #;
// $brand-success-02			: #;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Gray */
// $brand-gray-01				: #;
// $brand-gray-02				: #;

/*----------  Font Weight Variables  ----------*/

$hairline 			: 100;
$light	 			: 300;
$regular 			: 400;
$medium				: 500;
$semibold 			: 600;
$bold	 			: 700;
$extrabold	 		: 900;

/*----------  Font Size Variables  ----------*/

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base + rem-calc(2);		// 18px
$font-sm					: $font-base + rem-calc(4);		// 20px
$font-md					: $font-base + rem-calc(8);		// 24px
$font-lg					: $font-base + rem-calc(16);	// 32px
$font-xl					: $font-base + rem-calc(32);	// 48px
$font-xxl					: $font-base + rem-calc(48);	// 64px

/*----------  Grid  ----------*/

$grid-space-default			: 30;
$col-name					: 'cs-col';

/*----------  Containers  ----------*/

$container-default-gutter: 40;
$container-default-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1163px
);