/*----------  Header  ----------*/

header
{
	@include position(relative, 999);

	.header{
		&-container{
			padding: 20px 0px 0px;
			@include display-flex(row,center,space-between,nowrap);

			.nav{
				&-wrapper{
					@include display-flex(row,center,normal,nowrap);

					.nav-toggler-wrapper{
						display: none;
					}
				}
				&-container{
					.nav-list{
						@include display-flex(row,center,normal,nowrap);
					
						li{
							.nav-link{
								font-size: $font-base;
								font-weight: $bold;
								padding: 0.5rem 30px;
								color: $brand-secondary;

								&:hover, &:active, &:focus
								{
									color: $brand-primary;
								}
							}
	
							.nav-btn{
								margin: 0px 0px 0px 20px;
								padding: 10px 25px;
								background-color: $brand-primary;
								box-shadow: 0 8px 16px 0 rgba(128, 154, 208, 0.2);
								border-radius: 22px;
								@include font-p(14px, $bold, 1.2,$white-color);
								
								&:hover, &:active, &:focus
								{
									color: $brand-primary;
									background-color: $white-color;	
								}
							}
						}
					}
				}
			}

			@include media-breakpoint-down(md){
				.nav{
					&-container{
						.nav-list{
							li .nav-link{
								padding: 0.5rem 13px;
							}
						}
					}
				}
			}

			@include media-breakpoint-down(sm){
				.brand-wrapper {
					margin: 0px 20px 0px 0px;
					@include position(relative, 1000);
				}
				
				.nav{
					&-wrapper {
						.nav-toggler {
							&-wrapper {
								display: block;	
								@include position(relative, 1000);
								
								a{
									color: $brand-secondary;
								}
							}

							font-size: 32px;
							@include transition;
							&.open {
								@include transform(rotate(180deg));
								.las:before {
									content: '\f00d';
									font-size: 18px;
									padding: 11px;
									border: 1px solid $brand-secondary;
									border-radius: 99px;
								}
							}
						}
					}

					&-container{
						background-color: $brand-light;
						margin: 0;
						padding-left: 30px; 
						@include position(fixed, 999, 0, 0, 0, 0);
						@include display-flex(column, center, center);
						@include opacity(0);
						@include pointer-events(none);
						@include transition;

						&.show {
							@include opacity(1);
							@include pointer-events(all);
						}
					
						.nav-list {
							@include display-flex(column, center, center);

							.nav-item{
								margin-bottom: 13px;
								
								.nav-link{
									font-size: $font-lg;
									letter-spacing: 0.02em;
								}

								.nav-btn{
									font-size: $font-md;
									margin: 0px;
								}
							}
							li:last-child{
								margin: 10px 0px 0px;
							}
						}
					}
				}	
			}
			
			@include media-breakpoint-down(xs){
				.brand-wrapper {
					max-width: 200px;
					width: 100%;
				}
				
				.nav{
					&-wrapper {
						.nav-toggler {
							&.open {
								.las:before {
									padding: 9px;
								}
							}
						}
					}

					&-container .nav-list .nav-item{
						.nav-link{
							font-size: $font-xs;
						}

						.nav-btn{
							font-size: $font-xs;
						}
					}
				}	
			}
		}
	}
}