/*----------  Lists  ----------*/

.list-inline
{
	@include reset-list;
	@include display-inline-flex($wrap: wrap);

	li
	{
		@include reset;
	}
}

.list-inline-float
{
	@include clearfix;
	@include reset-list;

	li
	{
		display: block;
		float: left;

		@include reset;
	}
}