/*----------  Body  ----------*/

body
{
	@include font-p($font-base, $regular, 1.2, $brand-dark);
	background-color: #f9fbfe;
}

.container-xl {
    @include extend-container(1360px, 40px);
	margin: 0px auto;
}

.container-md {
    @include extend-container(1190px, 40px);
	margin: 0px auto;
}

li{
	list-style-type: none;
}

h1{
	@include font-p($font-xxl, $bold, 1, $brand-secondary);

	@include media-breakpoint-down(sm){
		font-size: $font-xl;
	}
}

p{
	@include font-p($font-xs, $light, 1.34, $brand-dark);
	
	@include media-breakpoint-down(sm){
		font-size: $font-base;
	}
}

button{
	border: none;
	outline: none;
}

.btn-theme{
	@include font-p($font-sm, $regular, 1, $white-color);
	padding: 17px 25px 17px 38px;
	border-radius: 32.5px;
	box-shadow: 0 8px 16px 0 rgba(128, 154, 208, 0.2);
	background-image: linear-gradient(to left,#b7caea ,#809ad0);
	background-size: 100% 100%;
	
	span{
		@include position;
		margin-left: 14px;

		&::before{
			content: '';
			@include position(absolute,-1,-2px);
			right: -11px;
			width: 30px;
			height: 30px;
			background-image: linear-gradient(to right, rgba(130, 156, 209, 0), #829bd1);
			border-radius: 32.5px;
		}
		svg{
			@include transition;
		}
	}

	&:hover, &:active, &:focus
	{	color: $white-color;
		background-size: 300% 100%;
		@include transition(2s);

		span{
			svg{
				transform: translateX(10px);
			}
		}
	}

	@include media-breakpoint-down(sm){
		font-size: $font-base;
	}
	@media screen and (max-width:370px){
		padding: 15px 22px 15px 20px;

		span{
			margin-left: 8px;
		}
	}
}

h2{
	@include font-p($font-xl, $bold, 1.2, $brand-secondary);
	
	@include media-breakpoint-down(sm){
		font-size: $font-lg;
	}
}

.services-container-cta{
	transform: translateY(-80px);
	box-shadow: 10px 10px 30px 0 rgba(80, 80, 80, 0.1);
	border-radius: 30px;
	background-color: $white-color;
	padding: 48px 60px;
	z-index: 9999;
	
	&-inner{
		@include display-flex(row,center,space-between,wrap);
		
		.content p{
			@include font-p($font-lg, $bold, 1.2, $brand-secondary);
		}
	}

	@include media-breakpoint-down(md){
		&-inner{
			.content{
				margin-bottom: 20px;
			}
		}
	}
	
	@include media-breakpoint-down(md){
		padding: 48px 25px;
		&-inner{
			justify-content: center;

			.content{
				text-align: center;
			}
		}
	}
}

.grecaptcha-badge{
	opacity: 0;
	cursor: none;
}

.cookiebar{
	&-wrapper{
		position: fixed;
		bottom: 0px;
		left: 0px;
		right: 0px;
		top: auto;
		background-color: $brand-primary;
		z-index: 999;
		box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.2);
	}

	&-container{
		padding: 15px 0px;
		@include display-flex(row,center,space-between,initial);

		.cookie-content{
			p{
				font-size: $font-base;
				line-height: 1.3;
				color: $white-color;

				.cookie-content-link{
					color: $white-color;
					@include transition;

					&:hover{
						color: $brand-secondary;
					}
				}
			}
			
			&-bold{
				font-weight: $bold;
			}
		}

		.btnAcceptCookie{
			padding: 6px 14px 7px;
			background-color: $brand-secondary;
			color: $white-color;
			font-weight: 500;
			border-radius: 30px;
			line-height: 1.2;
			letter-spacing: 1.68px;
			border: 1px solid $brand-secondary;
			@include transition;
			font-family: $font-primary;
			font-size: $font-base;

			&:hover{
				background-color: $white-color;
				color: $brand-secondary;
			}
		}
	}

	@include media-breakpoint-down(md){
		&-container{
			flex-direction: column;
			justify-content: center;
		
			.cookie-content{
				margin-bottom: 10px;
				text-align: center;
			}
		}
	}
}