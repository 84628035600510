/*----------  Footer  ----------*/
footer
{
	@include position;

	.footer{
		&-wrapper{
			background-image: linear-gradient(325deg, #223f6e 10%, #809ad0 100%);
			position: relative;
			
            &::before{
                content: '';
                @include position(absolute, -1, auto, 0px, auto, 297px);
                width: 158px;
                height: 388px;
                opacity: 0.63;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: #4a6dab;
            }
            &::after{
                content: '';
                @include position(absolute, -1, 0px, auto, 0px, auto);
                width: 55px;
                height: 232px;
                opacity: 0.63;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: #8db2fe;
            }
		}
		&-container{
			.footer{
				&-heading{
					margin: 13px 0px 39px;
					h2{
						color: $white-color;
					}
				}
				
				&-container-block{
					@include display-flex(row,flex-start,space-between,wrap);
					margin-bottom: 100px;

					.footer-container-form{
						max-width: 543px;
						width: 100%;

						.contact-form{
							&-title{
								margin-bottom: 30px;
								p{
									@include font-p($font-xs, $regular, 1.33, $white-color);
								}
							}

							.form-box{
								.form-group{
									margin-bottom: 20px;

									.form-input{
										height: 53px;
										padding: 17px 28px;
										border-radius: 4px;
										background-color: $brand-primary;
										opacity: 0.8;
										border: 1px solid transparent;
										@include font-p($font-xs, $regular, 1.2, $white-color);
										@include transition;

										&:hover, &:active, &:focus{
											border: 1px solid $white-color;
										}

										&::placeholder{
											@include font-p($font-base, $regular, 1.2, #dde8ff);
										}
										&:-moz-placeholder{
											@include font-p($font-base, $regular, 1.2, #dde8ff);
										}
										&::-webkit-input-placeholder{
											@include font-p($font-base, $regular, 1.2, #dde8ff);
										}
									}
								}	

								.form-group-message{
									margin-bottom: 40px;
									textarea{
										height: 150px !important;
									}
								}

								.form-checkbox{
									&:first-of-type{
										margin: 70px 0px 30px;
									}

									.custom-checkbox{
										position: absolute;
										opacity: 0;
									
										& + label{
											cursor: pointer;
											padding: 0;
											@include display-flex(row,flex-start,flex-start,initial,false);
	
											p{
												@include font-p($font-base, $regular, 1.5, $white-color);
												margin-left: 15px;

												a{
													color: $white-color;
													text-decoration: underline;
												}

												.bureaujdb-terms{
													text-decoration: none;

													&:hover, &:active, &:focus{
														color: $brand-secondary;
													}
												}
											}
	
											span {
												max-width: 24px;
												width: 100%;
												height: 24px;
												box-shadow: 0 8px 16px 0 rgba(128, 154, 208, 0.2);
												background-image: linear-gradient(to left, #b7caea, #809ad0);
												border-radius: 8px;
												@include transition(4s);
												@include position;
												top: 4px;
											}
										}
					
										// Box hover
										&:hover + label {
											span {
												background-size: 300% 100%;
												@include transition(4s);
											}
										}
									
										// Box checked
										&:checked + label {
											span {
												background-image: linear-gradient(to left, #b7caea, #809ad0);
											}
										}
									
										// Disabled state label.
										&:disabled + label {
											span {
												color: $brand-primary;
												cursor: auto;
											}
										}
									
										// Disabled box.
										&:disabled + label {
											span {
												box-shadow: none;
												background: #ddd;
											}
										}
									
										// Checkmark. Could be replaced with an image
										&:checked + label {
											span::after {
												content: '\f00c';
												font-family: $font-fontawesome;
												font-weight: $bold;
												position: absolute;
												left: 5px;
												top: 5px;
												font-size: 14px;
												line-height: 1;
												color: $white-color;
											}
										}
									}    		
								}
							}
						}
					}

					.footer-container-details{
						max-width: 543px;
						width: 100%;

						&-block{
							max-width: 246px;
							width: 100%;
							margin: 0px auto;

							.details{
								&-heading{
									margin-bottom: 34px;
									p{
										@include font-p($font-md, $bold, 1.2, $white-color);
									}
								}

								&-contact{
									margin-bottom: 63px;

									a{
										@include display-flex(row, center);
										margin-bottom: 20px;

										img{
											width: 20px;
										}
										p{
											margin-left: 18px;
											@include font-p($font-base, $regular, 1.5, $white-color);
											@include transition;
										}

										&:hover, &:active, &:focus{
											p{
												color: $brand-primary;
											}
										}

									}

									.address{
										align-items: flex-start;
										margin-bottom: 0px;

										img{
											margin-top: 6px;
										}
									}
								}
								&-social{
									margin-bottom: 39px;

									a{
										margin-right: 16px;
										padding: 7px 12px 8px 13px;
										border: 2px solid #7993c9;
										border-radius: 8px;
										opacity: 0.8;

										&:last-of-type{
											margin: 0px;
										}
										
										&:hover, &:active, &:focus{
											background-color: $brand-primary;

											svg g g{
												fill: $white-color;
											}
										}

									}

									.linkedin{
										padding: 7px 9px 8px 9px;
									}
									.twitter{
										padding: 7px 7px 8px;
									}
								}
								&-policy{
									margin-bottom: 255px;
									p{
										@include font-p($font-base, $regular, 1.5, $white-color);
									}
								}
							}
						}

						&-content{
							p{
								@include font-p($font-base, $regular, 1.5, $white-color);
								margin-bottom: 30px;

								&:last-of-type{
									margin-bottom: 0px;
								}

								a{
									color: $white-color;
									text-decoration: underline;

									&:first-of-type{
										text-decoration: none;
									}

									&:hover, &:active, &:focus{
										color: $brand-secondary;
									}
								}
							}
						}
					}

					@include media-breakpoint-down(lg){
						.footer-container-form{
							max-width: 440px;
						}
						.footer-container-details{
							max-width: 440px;
						}
					}
					
					@include media-breakpoint-down(md){
						@include display-flex(column,flex-start);
						.footer-container-form{
							max-width: none;
							margin-bottom: 40px;
						}
						.footer-container-details{
							max-width: none;
							
							&-block{
								margin: 0px auto;

								.details-policy{
									margin-bottom: 20px;
								}
							}
						}
					}

					@include media-breakpoint-down(xs){
						.footer-container-form .contact-form .form-box{
							.form-group-footer{
								@include display-flex(column-reverse,center);

								.form-submit{
									margin-top: 50px;
								}
							}

							.form-checkbox:first-of-type{
								margin: 0px 0px 30px;
							}
						}
					}
				}

				&-container-cta{
					padding: 22px 0px 40px;
					border-top: solid 1px #7992c8;
					@include display-flex(row,flex-start,space-between,wrap);

					p{
						@include font-p($font-base, $light, 1.5, $white-color);

						.ergonomic-website{
							color: $white-color;
							font-weight: $regular;
						}
					}
					.terms{
						max-width: 530px;
						width: 100%;
					}
					
					@include media-breakpoint-down(md){
						@include display-flex(column,center);
						.terms{
							max-width: none;
							text-align: center;
							margin-bottom: 20px;	
						}
					}
				}
			}
		}
	}
}