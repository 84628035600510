/*----------  Index View  ----------*/
.index-page{
    .banner{
        &-background{
            @include position(absolute, $z-index: -1, $top: 0, $left: 0, $right: 0);
            height: 760px;
            background: linear-gradient(303deg, rgba(240,245,254,1) 36%, rgba(255,255,255,1) 60%, rgba(240,245,254,1) 82%);
        }
        &-wrapper{
            padding: 46px 0px 79px;
            @include position;

            &::before{
                content: '';
                @include position(absolute, -1, 64px, 0px, auto, auto);
                width: 30px;
                height: 170px;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: #809ad0;
            }
            &::after{
                content: '';
                @include position(absolute, -1, 9px, auto, 0px, auto);
                width: 109px;
                height: 349px;
                opacity: 0.4;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-image: linear-gradient(165deg, #fff 14%, #5d8ae9 96%);
            }
        }
        
        &-container{
            @include display-flex(row,center,space-between,nowrap);
           
            &-content{
                max-width: 640px;
                width: 100%;

                p{
                    margin: 20px 0px 40px;
                    @include font-p($font-lg, $regular, 1, $brand-dark);
                    
                    b{
                        @include position;
                        &:before{
                            content: '';
                            @include position(absolute,-1);
                            width: 100%;
                            height: 10px;
                            bottom: 3px;
                            background-color: #c4d6fb;
                        }
                    }
                }

                .discover-btn{
                    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.2) 100%, #fff);
                    font-size: $font-md;
                    color: $brand-info;
                    position: relative;

                    span::before{
                        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #c4d6fb);
                    }
                    
                    &:hover, &:active, &:focus{
                        background-color: $brand-info;
                        @include transition;
                        color: $white-color;

                        span svg g g{
                            stroke: $white-color;
                        }
                    }
                    
                    &::after{
                        content: '';
                        @include position(absolute, -1, 39px, auto, -50px, auto);
                        width: 107px;
                        height: 107px;
                        -webkit-filter: blur(50px);
                        filter: blur(50px);
                        background-image: linear-gradient(165deg, #fff 14%, #5d8ae9 96%);
                    }
                }
            }

            &-media{
                @include position;

                &::before{
                    content: '';
                    @include position(absolute, 3, auto, -88px, auto, 44px);
                    width: 218px;
                    height: 72px;
                    background-image: url(' /assets/front/app/assets/images/index-banner-title.png');
                    @include background-defaults;
                }

                &::after{
                    content: '';
                    @include position(absolute, 3, 0px, auto, 24px, auto);
                    width: 140px;
                    height: 170px;
                    background-image: url(' /assets/front/app/assets/images/index-banner-logo.png');
                    @include background-defaults;
                }
            }
        }

        @include media-breakpoint-down(lg){
            &-container{
                &-content{
                    max-width: 500px;
                }
            }
        }
        @include media-breakpoint-down(md){
            &-wrapper{
                padding: 100px 0px 79px;
            }

            &-container{
                &-content{
                    max-width: 420px;
                    margin-right: 15px;
                    p{
                        font-size: $font-md;
                    }
                    .discover-btn{
                        font-size: $font-xs;
                    }
                }
                &-media{
                    max-width: 420px;
                    width: 100%;

                    &::after{
                        width: 120px;
                        height: 140px;
                    }
                    &::before{
                        left: -56px;
                        width: 188px;
                        height: 58px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm){
            &-background{
                height: 1086px;
            }
            &-container{
                display: block;

                &-content{
                    margin: 0px 0px 30px;
                }
                &-media{
                    max-width: 514px;
                    margin: 0px auto;
                }
            }
        }
        @include media-breakpoint-down(xs){
            &-container{
                &-content{
                    .discover-btn{
                        &::after{
                            display: none;
                        }
                    }
                }
                &-media{
                    &::before{
                        left: -10px;
                    }
                }
            }
        }
        @media screen and (max-width:370px){
            &-background{
                height: 880px;
            }
        }
    }
    
    .services{
        &-wrapper{
            padding: 95px 0px 227px;
            @include position;

            &-media{
                @include position(absolute,-1,auto,auto,31px,-51px);
            }

            &::before{
                content: '';
                @include position(absolute, -1, 223px, 0px, auto, auto);
                width: 39px;
                height: 517px;
                opacity: 0.8;
                -webkit-filter: blur(60px);
                filter: blur(60px);
                background-color: $brand-info;
            }
            &::after{
                content: '';
                @include position(absolute, -1, auto, auto, 0px, 144px);
                width: 121px;
                height: 388px;
                opacity: 0.4;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-primary;
            }
        }
        &-container{
            &-heading{
                @include display-flex(row, flex-start,space-between,nowrap);
                margin-bottom: 70px;
                p{
                    @include font-p($font-xs, $regular, 1.33, $brand-dark);
                    max-width: 543px;
                    width: 100%;
                }
            }
            &-content{
                @include display-flex(row,flex-start,space-between,nowrap);

                &-block{
                    max-width: 350px;
                    width: 100%;
                    background-color: $color-02;
                    border-radius: 20px;
                    border: solid 1.5px #e2ebfc;
                    @include transition;

                    .content-block-inner{
                        padding: 50px 30px;

                        .heading{
                            @include display-flex(column,flex-start);
                            margin-bottom: 25px;

                            .logo{
                                margin-bottom: 20px;
                                padding: 16px 19px;
                                border-radius: 20px;
                                background-image: linear-gradient(152deg, rgba(183, 198, 234, 0.07) 8%, #80c5d0 92%);
                            }
                            .approve-logo{
                                padding: 16px 17px 16px 16px;
                                background-image: linear-gradient(152deg, rgba(227, 183, 234, 0.07) 8%, #d080b9 92%);
                            }
                            .bill-logo{
                                padding: 20px 16px 19px 16px;
                                background-image: linear-gradient(152deg, rgba(234, 210, 183, 0.07) 8%, #d0bc80 92%);
                            }
                            p{
                                @include font-p($font-md, $bold, 1, $brand-secondary);
                            }
                        }
                        .content{
                            max-width: 250px;
                            width: 100%;
                            margin-left: 40px;

                            ul li{
                                @include position;
                                margin-bottom: 21px;
                                @include font-p($font-base, $regular, 1.5, $brand-dark);

                                &::before{
                                    content: '';
                                    background-image: url(' /assets/front/app/assets/images/list-check.png');
                                    @include position(absolute, 4, -3px, -40px, auto, auto);
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }

                    &:hover, &:active, &:focus{
                        @include transition;
                        box-shadow: 10px 10px 30px 0 rgba(182, 182, 182, 0.2);
                        border-color: transparent;
                        background-color: $white-color;
                    }
                }

                .administrative-block{
                    // border: none;
                    position: relative;

                    &::before{
                        content: '';
                        @include position(absolute, -1, auto, -37px, auto, -53px);
                        width: 107px;
                        height: 107px;
                        opacity: 0.4;
                        -webkit-filter: blur(50px);
                        filter: blur(50px);
                        background-color: $brand-info;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg){
            &-container{
                &-content{
                    &-block{
                        margin:0px 10px 20px;
                        max-width: 280px;

                        .content-block-inner{
                            .content{
                                max-width: 180px;   
                            }
                        }
                    }
                }
            }
        }
        
        @include media-breakpoint-down(md){
            &-container{
                &-heading{
                    @include display-flex(column);

                    h2{
                        margin-bottom: 20px;
                    }
                } 
                &-content{
                    justify-content: center;
                    flex-wrap: wrap;
                    
                    &-block{
                        max-width: 520px;
                        margin: 0px 0px 20px;

                        .content-block-inner{
                            padding: 50px 50px;

                            .content{
                                max-width: 360px;
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm){
            &-container{
                &-content{
                    &-block{
                        .content-block-inner{
                            .content{
                                max-width: 250px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width:400px){
            &-container{
                &-content{
                    &-block{
                       max-width: 280px;

                       .content-block-inner{
                            padding: 50px 15px;

                            .content{
                                margin-left: 40px;
                                max-width: 180px;
                            }
                       }
                    }
                }
            }
        }
        @media screen and (max-width:280px){
            &-container{
                &-content{
                    &-block{
                       .content-block-inner .content{
                            max-width: 150px;
                        }
                    }
                }
            }
        }
    }
   
    .about{
        &-wrapper{
            @include position;
            background-image: linear-gradient(326deg, #223f6e 10%, #809ad0 100%);
            padding: 0px 0px 210px;

            &::before{
                content: '';
                @include position(absolute, -1, 287px, 0px, auto, auto);
                width: 107px;
                height: 232px;
                opacity: 0.8;
                -webkit-filter: blur(60px);
                filter: blur(60px);
                background-color: #8db2fe;
            }
            &::after{
                content: '';
                @include position(absolute, -1, auto, auto, 0px, 187px);
                width: 86px;
                height: 388px;
                opacity: 0.64;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: #4a6dab;
            }
        }
        &-container{
            &-block{
                margin: 13px 0px 40px;

                .about-container{
                    &-heading{
                        @include display-flex(row, flex-start,space-between);
                        margin-bottom: 50px;

                        .heading{
                            max-width: 350px;
                            width: 100%;
                            h2{
                                color: $white-color;
                                line-height: 1.2;
                            }
                        }
                        .content{
                            max-width: 544px;
                            width: 100%;

                            .subtitle{
                                @include font-p($font-md, $bold, 1.2, $white-color);
                                margin-bottom: 10px;
                            }

                            p{
                                @include font-p($font-xs, $light, 1.34, $white-color);
                            }
                        }
                    }
                    
                    &-media{
                        margin-bottom: 30px;
                        img{
                            width: 100%;
                        }
                    }
                    &-content{
                        @include display-flex(row,flex-start,space-between);
                        p{
                            max-width: 543px;
                            width: 100%;
                            @include font-p($font-xs, $light, 1.34, $white-color);
                        }
                    }
                }
            }

            &-team{
                @include display-flex(row,center,space-between,wrap);
                &-content{
                    max-width: 542px;
                    width: 100%;

                    .team{
                        &-heading{
                            margin-bottom: 30px;
                            p{
                                @include font-p($font-lg, $bold, 1.2, $white-color);
                            }
                        }
                        &-text{
                            &-block{
                                &:first-of-type{
                                    margin-bottom: 40px;
                                }
                                .title{
                                    margin-bottom: 10px;
                                    @include font-p(28px, $bold, 1.2, $white-color);
                                }
                                p{
                                    @include font-p($font-xs, $light, 1.34, $white-color);
                                }
                                .bold{
                                    font-weight: $regular;
                                }
                            }
                        }  
                    }
                }
            }
        }
        @include media-breakpoint-down(lg){
            &-container{
                &-team{
                    &-content{
                        max-width: 460px;
                    }
                    &-media{
                        max-width: 460px;
                        width: 100%;
                    }
                }
            }
        }
        @include media-breakpoint-down(md){
            &-container{
                &-team{
                    @include display-flex(column);
                    &-content{
                        max-width: none;
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    &-media{
                        max-width: none;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm){
            &-container{
                &-block{
                    .about-container-heading{
                        @include display-flex(column);

                        .heading{
                            margin-bottom: 20px;
                        }
                    }
                    .about-container-content{
                        @include display-flex(column);
                        p{
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }

    .values{
        &-wrapper{
            @include position;
            padding: 0px 0px 107px;

            &::before{
                content: '';
                @include position(absolute, -1, 33px, 0px, auto, auto);
                width: 158px;
                height: 388px;
                opacity: 0.2;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-primary;
            }
            &::after{
                content: '';
                @include position(absolute, -1, 110px, auto, 0px, auto);
                width: 52px;
                height: 517px;
                opacity: 0.4;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-info;
            }
        }
        &-container{
            &-inner{
                margin-top: 21px;
                @include display-flex(row,flex-start,space-between,wrap);
            }

            &-heading{
                max-width: 253px;
                width: 100%;

                p{
                    margin-top: 50px;
                    @include font-p($font-base, $regular, 1.5, $brand-dark);
                }
            }
            &-listing{
                max-width: 775px;
                width: 100%;
                @include display-flex(row, flex-start, space-between,wrap);

                .container-listing-block{
                    max-width: 368px;
                    width: 100%;
                    @include display-flex(row, flex-start);
                    margin-bottom: 50px;

                    .our-values-icon{
                        padding: 17px;
                        margin-right: 24px;
                        opacity: 0.8;
                        border-radius: 8px;
                        box-shadow: 0 8px 16px 0 rgba(182, 182, 182, 0.2);
                        background-image: linear-gradient(to left, rgba(255, 255, 255, 0.2) 100%, #fff);
                    }
                    .our-values-business{
                        padding: 17px 15px 17px 16px;
                    }

                    .our-values-content{
                        .title{
                            @include font-p($font-md, $bold, 1.2, $brand-secondary);
                            margin-bottom: 10px;
                        }
                        p{
                            @include font-p($font-base, $regular, 1.5, $brand-dark);
                        }
                    }
                }

                .proactivite{
                    position: relative;

                    &::before{
                        content: '';
                        @include position(absolute, -1, 25px, -43px, auto, auto);
                        width: 107px;
                        height: 107px;
                        opacity: 0.4;
                        -webkit-filter: blur(50px);
                        filter: blur(50px);
                        background-color: $brand-info;
                    }
                }
            }
        }
        
        @include media-breakpoint-down(lg){
            &-container{
                &-inner{
                    .values-container-heading{
                        max-width: 235px;
                    }
                    .values-container-listing{
                        max-width: 630px;
                        .container-listing-block{
                            max-width: 300px;
                        }
                    }
                }
            }
        }
        
        @include media-breakpoint-down(md){
            &-container{
                &-inner{
                    display: block;

                    .values-container-heading{
                        max-width: none;
                        margin-bottom: 40px;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm){
            &-container{    
                &-inner{
                    .values-container-listing{
                        max-width: none;
                        @include display-flex(column,center);

                        .container-listing-block{
                            max-width: 368px;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(xs){
            &-container{    
                &-inner{
                    .values-container-listing{
                        .container-listing-block .our-values-content .title{
                            font-size: $font-xs;
                        }
                    }
                }
            }
        }
    }

    .company{
        &-wrapper{
            padding: 86px 0px 142px;
            background-color: #ecf1fc;
            @include position;
            
            &::before{
                content: '';
                @include position(absolute, -1, auto, 0px, auto, 20px);
                width: 46px;
                height: 234px;
                opacity: 0.6;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-info;
            }
            &::after{
                content: '';
                @include position(absolute, -1, 72px, auto, 0px, auto);
                width: 45px;
                height: 359px;
                opacity: 0.4;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-info;
            }
        }
        &-container{
            &-block{
                @include display-flex(row, flex-start,space-between, wrap);
                margin-bottom: 38px;
                &-content{
                    max-width: 580px;
                    width: 100%;
                    margin-top: 34px;

                    h2{
                        margin-bottom: 40px;
                        position: relative;

                        &::before{
                            content: '';
                            @include position(absolute, -1, -68px, -113px, auto, auto);
                            width: 143px;
                            height: 143px;
                            opacity: 0.2;
                            -webkit-filter: blur(50px);
                            filter: blur(50px);
                            background-color: $brand-info;
                        }
                    }
                    p{
                        @include font-p($font-xs, $regular, 1.34, $brand-dark);
                        margin-bottom: 46px;

                        &:first-of-type{
                            margin-bottom: 24px;
                        }
                    }
                }
                &-media{
                    max-width: 447px;
                    width: 100%;
                    @include display-flex(column,center);

                    .company-media{
                        margin-bottom: 40px;
                    }

                    p{
                        @include font-p($font-xs, $regular, 1.34, $brand-dark);
                    }
                }
            }
            &-button{
                @include display-flex(column,center);
                p{
                    @include font-p($font-xs, $regular, 1.34, $brand-dark);
                    margin-bottom: 22px;
                }
            }
        }

        @include media-breakpoint-down(lg){
            &-container{
                &-block{
                    &-content{
                        max-width: 485px;
                    }
                    &-media{
                        max-width: 435px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md){
            &-container{
                &-block{
                    display: block;
                    &-content{
                        max-width: none;
                    }
                    &-media{
                        max-width: none;
                    }
                }
            }
        }
    }

    .quality{
        &-wrapper{
            padding: 99px 0px 172px;
            @include position;

            &::before{
                content: '';
                @include position(absolute, -1, auto, 0px, auto, 70px);
                width: 46px;
                height: 107px;
                opacity: 0.6;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-info;
            }
            &::after{
                content: '';
                @include position(absolute, -1, 81px, auto, 0px, auto);
                width: 42px;
                height: 107px;
                opacity: 0.6;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: $brand-info;
            }
        }
        &-container{
            &-block{
                @include display-flex(row,flex-start,space-between,wrap);
                
                &-content{
                    max-width: 579px;
                    width: 100%;
                    margin-top: 11px;

                    p{
                        @include font-p($font-xs, $regular, 1.34, $brand-dark);
                        &:first-of-type{
                            margin: 20px 0px 24px;
                        }
                    }
                }
                &-media{
                    @include position;
                    &::before{
                        content: '';
                        @include position(absolute, -1, -47px, -220px, auto, auto);
                        width: 235px;
                        height: 235px;
                        opacity: 0.2;
                        -webkit-filter: blur(50px);
                        filter: blur(50px);
                        background-color: $brand-info;
                    }
                }
            }
        }
        @include media-breakpoint-down(lg){
            &-container{
                &-block{
                    &-content{
                        max-width: 470px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md){
            &-container{
                &-block{
                    @include display-flex(column,center);
                    &-content{
                        max-width: none;
                        margin-bottom: 40px;
                    }
                    &-media{
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}